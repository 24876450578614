.NewsMainBanner {
  width: 100%;
  height: 100vh;
  background: #0b0b0b;
  position: absolute;
  z-index: 0;
}
.NewsMainBanner {
    h2 {
      color: #f2fafd;
      font-family:
              Montserrat,
              sans-serif;
      font-size: 80px;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 5px;
      z-index: 2;
      user-select: none;
    }
    .ParticleMainTitle-company {
      color: #969696;
      font-family:
              Montserrat,
              sans-serif;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 5px;
      z-index: 2;
      user-select: none;
        &--Label {
          color: #969696;
          font-family:
                  Montserrat,
                  sans-serif;
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 1px;
          z-index: 2;
          user-select: none;
        }
    }
}
.particles_f {
  position: relative;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 250px;
}
.banner-label {
  max-width: 336px;
  color: #A6A6A6;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 33.02px */
  letter-spacing: 2.1px;
  user-select: none;
}
.particle_button_cont {
  margin-top: 320px;
  margin-left: 200px;
  display: flex;
  flex-direction: column;
  gap: 230px;
}

.ParticleMainTitle {
  margin-right: 200px;
}

.particle_button {
  width: 229px;
  height: 64px;
  background: rgba(231, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: all 0.3s;

  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 1.575px;
}
.particle_button:hover {
  width: 219px;
  font-size: 13px;
  height: 59px;
  margin: 2.5px 5px;
  border-radius: 5px;
}
.form-responsive {
  transform: none;
}
.MainBanner {
  width: 100%;
  height: 100vh;
  background-size: cover;
}

@media screen and (max-width: 1500px) {
  .particles_f {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding: 0 200px;
    gap: 80px;
  }
  .particle_button_cont {
    margin: 0;
    gap: 70px;
  }
  .ParticleMainTitle {
    margin-top: 150px;
    margin-right: 150px;
  }
}
@media screen and (max-width: 900px) {
  .MainBanner {
    height: 100vh;
  }
  .NewsMainBanner {
    height: 100%;
  }
  .NewsMainBanner h2 {
    font-size: 70px;
    .ParticleMainTitle-company {
      font-size: 70px;
      &--Label {
        font-size: 40px;
      }
    }
  }
  .particles_f {
    padding: 0 100px;
  }
}
@media screen and (max-width: 620px) {
  .MainBanner {
    height: 794px;
  }
  .NewsMainBanner {
    height: 794px;
  }
  .NewsMainBanner h2 {
    font-size: 50px;
    line-height: 100%; /* 33.02px */
    .ParticleMainTitle-company {
      font-size: 50px;
      &--Label {
        font-size: 20px;
      }
    }
  }
  .particles_f {
    padding: 0 50px;
  }
}
@media screen and (max-width: 400px) {
  .MainBanner {
    height: 650px;
  }
  .NewsMainBanner {
    height: 650px;
  }
  .NewsMainBanner h2 {
    font-size: 40px;
    .ParticleMainTitle-company {
      font-size: 40px;
      &--Label {
        font-size: 15px;
      }
    }
  }
  .banner-label {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
  }
}

@media screen  and (max-height: 600px) and (max-width: 1024px){
  .ParticleMainTitle {
    margin-top: 50px;
  }
}
