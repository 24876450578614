.footer {
  width: 100%;
  padding: 105px 170px;
  display: flex;
  justify-content: center;
  align-items: center;
    img {
      margin-left: 20px;
    }
}
.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-content h3 {
  color: #a1a1a1;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 148.1%; /* 22.215px */
  letter-spacing: 1.2px;
}
.footer-content--text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 980px) {
  .footer {
    padding: 105px 50px;
  }
}
@media screen and (max-width: 550px) {
  .footer-content--text {
    flex-direction: column;
    align-items: flex-start;
  }
}
