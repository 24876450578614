.ChooseSection-container {
  width: 100%;
  padding: 0 100px;
}
.ChooseSection-Desktop {
  position: relative;
  width: 100%;
  min-height: 580px;
  padding: 60px 75px;
  background: #181818;
}
.ChooseSection-Desktop-mobile {
  display: none;
}
.TabsBackground {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  max-width: 100%;
}
//.TabsBackground-light {
//  opacity: 0.5;
//  top: 10vh;
//  right: 38vh;
//  min-width: 445px;
//  min-height: 445px;
//  position: absolute;
//  z-index: 0;
//  border-radius: 445px;
//  background: radial-gradient(50% 50% at 50% 50%, rgba(245, 40, 42, 0.36) 8.85%, rgba(60, 60, 60, 0.00) 85.94%);
//}

.ChooseSection-Main-container {
  max-width: 1780px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

//@media screen and (max-width: 1700px){
//  .TabsBackground {
//    right: 130px;
//  }
//}
//@media screen and (max-width: 1650px){
//  .TabsBackground {
//    right: 100px;
//  }
//}
//@media screen and (max-width: 1600px){
//  .TabsBackground {
//    right: 70px;
//  }
//}
//@media screen and (max-width: 1530px){
//  .TabsBackground {
//    right: 40px;
//  }
//}
//@media screen and (max-width: 1490px){
//  .TabsBackground {
//    right: 20px;
//  }
//}
//@media screen and (max-width: 1450px){
//  .TabsBackground {
//    right: 0;
//  }
//}

//@media screen and (max-width: 1400px){
//  .TabsBackground {
//    display: none;
//  }

//}
@media screen and (max-width: 1100px) {
  .ChooseSection-Desktop {
    padding: 50px 25px;
  }
  .ChooseSection-container {
    display: none;
    padding: 0 25px;
  }
  .ChooseSection-Desktop-mobile {
    display: flex;
    margin: 0 0 70px 0;
  }
}
@media screen and (max-width: 530px) {
  .ChooseSection-container {
    padding: 0;
  }
}

