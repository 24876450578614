.titleLetter-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  span {
    color: #a1a1a1;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 165.1%; /* 24.765px */
    letter-spacing: 1.575px;
    margin-right: 10px;
    user-select: none;
  }
  p {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 165.1%; /* 33.02px */
    letter-spacing: 1.4px;

    max-width: 270px;
    user-select: none;
  }
  h2 {
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 67px;
    font-style: normal;
    font-weight: 700;
    line-height: 125.1%; /* 83.817px */

    max-width: 445px;
    user-select: none;
  }
  h3 {
    color: #f2fafd;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 165.1%; /* 24.765px */
    letter-spacing: 1.575px;
    user-select: none;
  }
}

.titleLetter-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .titleLetter-container {
    justify-content: flex-start;
    align-items: start;
  }
}


@media screen and (max-width: 620px) {
  .titleLetter-text {
    p {
      font-size: 15px;
    }
    h2 {
      font-size: 40px;
      max-width: 200px;
    }
  }
}
