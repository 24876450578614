.ProjectDescription {
  margin-top: 100px;
  width: 100%;
  padding: 50px 50px;
  background: #181818;
}
.ProjectDescription-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ProjectDescription-titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ProjectDescription-linkToProject {
  color: red;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 1.575px;
  max-width: 200px;
  cursor: pointer;

  transition: all 0.3s;
}
.ProjectDescription-linkToProject::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: white;
  transition: width 0.5s;
}
.ProjectDescription-linkToProject:hover::after {
  width: 100%;
}
.ProjectDescription-title {
  color: #F2FAFD;
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 2px;
}
.ProjectDescription-number {
  color: #A1A1A1;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 1.575px;
}
.ProjectDescription-line {
  width: 100%;
  height: 1px;
  background: #A1A1A1;
}
.ProjectDescription-text {
  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 33.02px */
  letter-spacing: 1.4px;

  max-width: 1165px;

    &_hidden {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
}

@media screen and (max-width: 730px) {
  .ProjectDescription-title {
    font-size: 20px;
  }
  .ProjectDescription-number {
    font-size: 13px;
  }
  .ProjectDescription-text {
    font-size: 17px;
  }
}
@media screen and (max-width: 550px) {
  .ProjectDescription-titleContainer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
  .ProjectDescription {
    padding: 20px 20px;
  }
}