.ContactsSection {
  width: 100%;
  padding: 250px 150px;
  display: flex;
  flex-direction: column;
  max-width: 1780px;
  margin: 0 auto;
}
.ContactsSection-topPart {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  user-select: none;
  &--title {
    color: #f2fafd;
    text-align: center;
    font-family:
            Montserrat,
      sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 125.6%; /* 62.8px */
    letter-spacing: 3.5px;
    text-transform: uppercase;
    width: 200%;
    max-width: 1200px;
  }
}
.ContactsSection-topPart--titleBackground {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
  top: -30vh;

  width: 738px;
  height: 738px;
  border-radius: 738px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(245, 40, 42, 0.36) 8.85%,
    rgba(60, 60, 60, 0) 85.94%
  );
}

.ContactsSection-bottomPart {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ContactsSection-bottom-leftPart {
  display: flex;
  flex-direction: column;
  gap: 50px;
  &--mail {
    color: #f2fafd;
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 311.1%; /* 77.775px */
    letter-spacing: 2px;
    transition: all 0.5s;
  }
  &--mail:hover {
    letter-spacing: 1px;
  }
  &--line {
    max-width: 370px;
    height: 1px;
    background: #bcbcbc;
  }
  &--tel {
    color: #f2fafd;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 199.1%; /* 39.82px */
    letter-spacing: 1.6px;
    transition: all 0.5s;
  }
  &--tel:hover {
    letter-spacing: 1px;
  }
  &--adress {
    color: #f2fafd;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 199.1%; /* 39.82px */
    letter-spacing: 1.6px;
    white-space: nowrap;
  }
}

.ContactsSection-bottom-rightPart {
  display: flex;
  align-items: center;
  gap: 70px;
  &--links {
    transition: all 0.3s;
  }
  &--links:hover {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1140px) {
  .ContactsSection-topPart--titleBackground {
    top: -25vh;
  }
}
@media screen and (max-width: 1040px) {
  .ContactsSection-bottom-rightPart {
    gap: 40px;
  }
  .ContactsSection {
    padding: 50px 75px;
  }
}
@media screen and (max-width: 860px) {
  .ContactsSection-topPart {
    &--title {
      font-size: 30px;
    }
  }
  .ContactsSection-topPart--titleBackground {
    display: none;
  }
  .ContactsSection {
    padding: 15px 75px;
  }
}
@media screen and (max-width: 780px) {
  .ContactsSection-bottomPart {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .ContactsSection-bottom-leftPart {
    align-items: center;
  }
  .adress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 460px) {
  .ContactsSection-topPart {
    &--title {
      font-size: 25px;
    }
  }
  .ContactsSection-bottom-rightPart {
    gap: 25px;
  }
  .ContactsSection-bottom-leftPart {
    &--mail {
      font-size: 20px;
    }
  }
  .ContactsSection {
    padding: 15px 50px;
  }
}
