.ConnectsSection-container {
  max-width: 1780px;
  margin: 0 auto;
  width: 100%;
}
.ConnectsSection-topPart {
  padding: 100px 220px;
  position: relative;
  margin-top: 100px;
}
.TitleLetterBackground {
  position: absolute;
  width: 55%;
  left: 0;
}
.LogoLetterContainer {
  position: relative;
}

//.ConnectsSection-desktop {
//  width: 100%;
//  min-height: 610px;
//  background: #181818;
//}

.ConnectsSection-AccordionContainer {
  padding: 10px 100px;
}

.LogoLetterBackground__Connects {
  position: absolute;
  left: -110px;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  .ConnectsSection-topPart {
    display: flex;
    gap: 50px;
    flex-direction: column;
    align-items: flex-start;
  }
  .TitleLetterBackground {
    top: 125px;
    width: 75%;
  }
  .ChooseSection-topPart {
    display: flex;
    gap: 50px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 860px) {
  .ConnectsSection-topPart {
    padding: 50px 100px;
  }
  .ChooseSection-topPart {
    padding: 50px 100px;
  }
  .ConnectsSection-AccordionContainer {
    padding: 10px 25px;
  }
  .TitleLetterBackground {
    top: 75px;
    width: 95%;
  }
}

@media screen and (max-width: 530px) {
  .LogoLetter {
    width: 60%;
  }
  .LogoLetterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ConnectsSection-topPart {
    padding: 50px 50px;
  }
  .ChooseSection-topPart {
    padding: 50px 50px;
  }
  .LogoLetterBackground__Connects {
    display: none;
  }
  .ConnectsSection-AccordionContainer {
    padding: 10px 0;
  }
  .TitleLetterBackground {
    top: 85px;
    width: 100%;
  }
}
@media screen  and (max-height: 600px) and (max-width: 1024px){
  .ConnectsSection-topPart {
    margin-top: 300px;
  }
}
