.accordion-wrapper {
  width: 100%;
  background: #181818;
  padding: 0 100px;
}

.accordion {
  list-style-type: none;
}

.accordion-item {
  background: #a11b1b;
}

.accordion-header {
  position: relative;
  width: 100%;
  min-height: 120px;
  background: #181818;
  border: 0;
  border-bottom: 1px solid #bcbcbc;
  text-align: left;
  cursor: pointer;
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 311.1%; /* 77.775px */
  letter-spacing: 2px;
  padding-left: 100px;
}
.accordion-header.button-active {
  border-bottom: none;
}

.accordion-collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.5s;
}
.accordion-collapse.open {
  height: auto;
}

.accordion-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px 100px 25px 100px;
}
.accordion-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.accordion-text {
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 33.02px */
  letter-spacing: 2.1px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-right: 10px;
}
//.accordion-arrow {
//  position: absolute;
//  top: 50%;
//  left: 70px;
//  display: none;
//  transform: translateY(-50%);
//  transition: all 0.5s;
//}
//.accordion-arrow.active {
//  display: block;
//  transition: all 0.5s;
//}

.accordion-arrow-red {
  position: absolute;
  top: 50%;
  left: 70px;
  display: block;
  transform: translateY(-50%);
  transition: all 0.5s;
}
.accordion-arrow-red.active-red {
  transform: translateY(-50%) rotate(180deg);
  display: block;
  fill: #e70000;
  transition: all 0.5s;
}
.accordion-static-part {
  padding: 40px 100px;
}
.accordion-static-part span {
  color: #e70000;
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 311.1%; /* 77.775px */
  letter-spacing: 2px;
}
.accordion-static-part-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.accordion-static-part-images {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1340px) {
  //.accordion-body {
  //  justify-content: start;
  //  align-items: start;
  //}
  .accordion-text {
    color: #f2fafd;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 165.1%; /* 19.812px */
    letter-spacing: 1.26px;
  }
}
@media screen and (max-width: 840px) {
  .accordion-header {
    font-size: 17px;
    font-weight: 700;
    line-height: 311.1%; /* 52.887px */
    letter-spacing: 1.36px;
  }
  .accordion-text {
    font-size: 12px;
  }
  .accordion-static-part span {
    color: #e70000;
    font-family: Montserrat, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 311.1%; /* 52.887px */
    letter-spacing: 1.36px;
  }
  .accordion-wrapper {
    padding: 0 20px;
  }
}

@media screen and (max-width: 911px) {
  .accordion-static-part {
    padding: 40px 0;
  }
}

@media screen and (max-width: 390px) {
  .accordion-header {
    font-size: 15px;
  }
}

@media screen and (max-width: 520px) {
  .accordion-header {
    padding-left: 30px;
  }
  .accordion-body {
    padding: 25px 50px 15px 50px;
  }
  .accordion-arrow-red {
    left: 7px;
  }
}
