.SliderAboutUs {
  width: 100%;
}
.AboutUs-Section-Slider {
  width: 100%;
  padding: 50px 20px;
}

.SwiperAboutUs {
  height: 500px;
}
.AboutUs-Section-SliderContainer {
  min-height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
    h3 {
      color: #FFF;
      text-align: center;
      font-family: Montserrat, sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 165.1%; /* 41.275px */
      letter-spacing: 1.75px;
    }
    p {
      color: #FFF;
      text-align: center;
      font-family: Montserrat, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 165.1%; /* 33.02px */
      letter-spacing: 1.4px;
      max-width: 300px;
    }
}
.AboutUs-Slider-Title {
  color: red;
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 311.1%; /* 77.775px */
  letter-spacing: 2px;
  text-align: center;
}

@media screen and (max-width: 430px) {
  .AboutUs-Slider-Title {
    font-size: 20px;
  }
  .AboutUs-Section-SliderContainer {
      p {
        font-size: 17px;
        max-width: 200px;
      }
      h3 {
        font-size: 20px;
      }
  }
}
@media screen and (max-width: 330px) {
  .AboutUs-Slider-Title {
    font-size: 17px;
  }
}