.Form {
  transform: translateX(250vh);
  position: fixed;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 100px 100px;
  background: #181818;
  display: flex;
  flex-direction: column;
  z-index: 20;
  transition: all 0.3s;
    &-container {
      margin: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 50px;
    }
    &-show {
      transform: none;
    }
    &-closeBtn {
      position: absolute;
      border: none;
      background: none;
      cursor: pointer;
      top: 20px;
      right: 30px;
      transition: all 0.5s;
        img {
          transition: all 0.3s;

          &:hover {
            scale: 0.8;
          }
        }
    }
}

body.Form-show {
  overflow: hidden;
}
.Form-container-topPart {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
    p {
      max-width: 700px;
    }
    h2 {
      max-width: 700px;
    }
}
.Form-Part-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
    div {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
}

.Form-Button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 30px;
}

.FormButton {
  display: flex;
  align-items: center;
  min-width: 400px;
  padding: 5px 35px;
  border: 0.5px solid #F2FAFD;
  background: none;
  cursor: pointer;

  color: #F2FAFD;
  font-family: Montserrat, sans-serif ;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 311.1%; /* 62.22px */
  letter-spacing: 1.6px;
  transition: all 0.5s;
}
.FormArrow {
  transition: all 0.5s;
  margin-right: 12px;
}
.FormArrowWhite {
  display: none;
}
.FormButton_active {
  background: red;
    .FormArrow {
      display: none;
    }
  .FormArrowWhite {
    transition: all 0.5s;
    margin-right: 12px;
    display: block;
  }
}

.Form-information {
  padding: 5px 20px;
  min-width: 400px;
  background: transparent;
  border: 0.5px solid #F2FAFD;
  outline: none;

  color: #F2FAFD;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 33.02px */
  letter-spacing: 2.1px;

  &_error {
    &::-webkit-input-placeholder {
      color: red;
    }
  }
}

.error {
  color: red;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 2.1px;
}
.Form-Part-describeContainer {
  width: 100%;
}
.Form-describe {
  background: none;
  min-width: 100%;
  height: 150px;
  outline: none;
  resize: none;

  padding: 15px 20px;

  color: #F2FAFD;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 33.02px */
  letter-spacing: 2.1px;
}

.Form-submitPart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.Form-submitText {
  color: #A5A5A5;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 165.1%; /* 23.114px */
  letter-spacing: 0.98px;
}
.Form-submitButton {
  padding: 20px 80px;
  background: #e70000;
  border: none;

  cursor: pointer;
  color: #F2FAFD;
  font-family: Montserrat,  sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 1.575px;
  transition: all 0.5s;

  &_disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
}
.Form-submitButton:hover {
  padding: 18px 77px;
  margin: 2px 3px;
  border-radius: 5px;
}

@media screen and (max-width: 980px) {
  .Form {
    padding: 100px 30px;
  }

  .FormButton {
    min-width: 350px;
    padding: 5px 25px;

    font-size: 15px;
    letter-spacing: 1px;
  }
  .Form-information {
    min-width: 350px;
  }
}

@media screen and (max-width: 800px) {
  .Form-Part-container {
    flex-direction: column;
  }
  .Form-Button-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 30px;
  }
  .Form-describe {
    display: none;
  }
  .Form-container {
    gap: 25px;
  }
  .Form {
    padding: 100px 30px;
  }
  .Form-submitPart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 30px;
  }
  .Form-submitButton {
    width: 300px;
  }
  .Form-submitText {
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .Form {
    padding: 30px 15px;
  }

  .FormButton {
    min-width: 100%;
    padding: 5px 20px;

    font-size: 15px;
    letter-spacing: 0.6px;
  }

  .Form-submitButton {
    width: 100%;
  }

  .Form-information {
    padding: 5px 30px;
    min-width: 100%;
  }

  .Form-closeBtn {
    top: 18px;
    right: 15px;
    z-index: 10;
    img {
      width: 70%;
    }
  }
}

@media screen and (max-width: 350px) {
  .FormButton {
    padding: 5px 10px;
    font-size: 14px;
  }
  .Form-information {
    padding: 5px 20px;
  }
}