.CollaboratorItem-name {
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 77.775px */
  letter-spacing: 2px;

  min-width: 290px;
}
.CollaboratorItem-position {
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 2.1px;
  text-align: left;

  min-width: 150px;
  max-width: 150px;
}
.CollaboratorItem-description {
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 1.4px;

  max-width: 479px;
}
.CollaboratorItem-content {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 1400px) {
  .CollaboratorItem-name {
    font-size: 20px;
  }
  .CollaboratorItem-position {
    font-size: 15px;
    max-width: 150px;
  }
  .CollaboratorItem-description {
    font-size: 15px;
    max-width: 296px;
  }
  .CollaboratorItem-content {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
}
@media screen and (max-width: 860px) {
  .CollaboratorItem-position {
    color: #f2fafd;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 2.1px;
    text-align: center;

    min-width: 150px;
    max-width: 150px;
  }
  .CollaboratorItem-name {
    color: #f2fafd;
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 77.775px */
    letter-spacing: 2px;
    text-align: center;

    min-width: 290px;
  }
  .CollaboratorItem-content {
    margin: 30px 0;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
  }
  .CollaboratorItem-description {
    text-align: center;
  }
}
@media screen and (max-width: 420px) {
  .CollaboratorItem-description {
    width: 70%;
  }
}