.ProjectCard-title {
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 40px;
}
.ProjectCard-label {
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2.1px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ProjectCard-image {
  max-width: 531px;
  max-height: 350px;
  width: 100%;
}
.ProjectCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media screen and (max-width: 880px) {
  .ProjectCard-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 311.1%; /* 46.665px */
    letter-spacing: 1.2px;
  }
  .ProjectCard-label {
    font-size: 15px;
  }
}
@media screen and (max-width: 530px) {
  .ProjectCard-title {
    margin-left: 30px;
  }
  .ProjectCard-label {
    margin-left: 30px;
  }
}
