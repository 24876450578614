.Tabs-Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.content {
  display: block;
  color: white;

  h2 {
    color: #fff;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 165.1%; /* 41.275px */
    letter-spacing: 1.75px;
    margin-bottom: 70px;
    animation-duration: 0.5s;
    animation-name: slidein;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 165.1%; /* 33.02px */
    letter-spacing: 1.4px;
    max-width: 353px;
    animation-duration: 0.5s;
    animation-name: slidein;
  }
}

@keyframes slidein {
  from {
    margin-left: 1000%;
    width: 300%;
  }

  to {
    margin-left: 0;
    width: 100%;
  }
}
.disabled {
  display: none;
}
.Bloc-Tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}
.Tabs-Title {
  background: none;
  cursor: pointer;
  border: none;
  position: relative;
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 2px;
  transition: all 0.5s;
  white-space: nowrap;
}

.Tabs-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
.active-Tabs-Title {
  color: red;
}
.Tabs-Label {
  color: #a1a1a1;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 1.575px;
  margin-right: 15px;
}
.Tabs-Title--Line {
  width: 120%;
  height: 1px;
  background: #bcbcbc;
}

@media screen and (max-width: 1400px) {
  .Tabs-Title--Line {
    width: 100%;
  }
  .Tabs-Container {
    gap: 50px;
  }
}
@media screen and (max-width: 1100px) {

  .Bloc-Tabs {
    display: none;
  }
  .Tabs-Content {
    display: none;
  }

  .Tabs-Container {
    flex-direction: column;
  }

}
@media screen and (max-width: 625px) {
  .Bloc-Tabs {
    max-width: 300px;
  }
}
