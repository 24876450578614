.PortfolioSection-Main-container {
  max-width: 1780px;
  width: 100%;
  margin: 0 auto;
}
.PortfolioSection-container {
  width: 100%;
  padding: 0 100px;
}
.PortfolioSection-Desktop {
  width: 100%;
  padding: 60px 75px;
  background: #181818;
}
.PortfolioSection-Desktop-topPart {
  max-width: 1620px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  gap: 30px;
}
.PortfolioSection-Desktop-line {
  width: 100%;
  height: 1px;
  background: #bcbcbc;
  margin-top: 62px;
}
.PortfolioSection-Desktop-link {
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  transition: all 0.5s;

  max-width: 350px;
  svg {
    margin-left: 10px;
    transition: all 0.5s;
  }
}
.PortfolioSection-Desktop-link:hover {
  letter-spacing: 1.7px;
  svg {
    margin-left: 5px;
  }
}
.LogoLetterBackground {
  position: absolute;
  top: -20px;
  left: -150px;
}

@media screen and (max-width: 1450px) {
  .PortfolioSection-Desktop-topPart {
    flex-direction: column;
  }
}
@media screen and (max-width: 860px) {
  .PortfolioSection-container {
    padding: 0 25px;
  }
  .PortfolioSection-Desktop {
    padding: 40px 25px;
  }
}
@media screen and (max-width: 530px) {
  .LogoLetterBackground {
    display: none;
  }
  .PortfolioSection-container {
    padding: 0;
  }
  .PortfolioSection-Desktop {
    padding: 0 0 40px 0;
  }
  .PortfolioSection-Desktop-link {
    margin-left: 30px;
  }
  .ProjectCard-none {
    display: none;
  }
}
