.InscriptionSection {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  overflow: hidden;
}
.Inscription {
  color: #F2FAFD;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Montserrat,sans-serif;
  font-size: 140px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 85px;
  line-height: 140px;
  overflow: hidden;
  transition: all 1s;
  white-space: nowrap;
  text-align: start;
  width: 100%;

  border: none;
  background: transparent;
  cursor: pointer;
}
.Inscription::after {
  content: '';
  display: block;
  width: 0;
  height: 5px;
  background: red;
  transition: width 1s;
}
.Inscription:hover::after {
  width: 60%;
  //transition: width .3s;
}

.Inscription:hover {
  letter-spacing: 1px;
}
.Inscription-BottomLine {
  width: 100%;
  height: 5px;
  background: red;
}

@media screen and (max-width: 1770px) {
  .Inscription {
    font-size: 100px;
    letter-spacing: 70px;
  }
  .Inscription:hover::after {
    width: 55%;
    //transition: width .3s;
  }
}
@media screen and (max-width: 1350px) {
  .Inscription {
    font-size: 70px;
    letter-spacing: 59px;
    line-height: 80px;
  }
}

@media screen and (max-width: 1040px) {
  .Inscription {
    border-bottom: red 5px solid;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 500px) {
  .Inscription {
    font-size: 50px;
    line-height: 50px;
  }
}
@media screen and (max-width: 370px) {
  .Inscription {
    font-size: 40px;
  }
}