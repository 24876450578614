.OurTeamSection-Main-container {
  max-width: 1780px;
  width: 100%;
  margin: 0 auto;
}
.OurTeamSection-line {
  width: 100%;
  height: 1px;
  background: #bcbcbc;
}

.OurTeamSection-container {
  margin: 0 100px;
  padding: 30px 55px;
  background: #181818;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}
.swiper-pagination-bullet-active {
  background-color: #920101 !important;
}
.swiper-pagination-bullet {
  background-color: #FFFFFF;
}

.OurTeamSection-container-mobile {
  display: none;
}

.CollaboratorItem-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}


.react-slideshow-container .nav {
  z-index: 1;
}

.Slider-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 70px;
}

.Slider-container-mobile {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ConnectsSection-topPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LogoLetterBackground__OurTeam {
  position: absolute;
  left: -130px;
  top: -20px;
  z-index: -1;
}
.indicators {
  display: flex;
  gap: 20px;
  .indicator {
    cursor: pointer;
    border-radius: 50%;
    background: #a1a1a1;
    border: 1px solid #a1a1a1;
    width: 10px;
    height: 10px;
    transition: all 0.3s;
  }
  .indicator.active {
    background: transparent;
    border: 1px solid #fff;
  }
}

@media screen and (max-width: 1500px) {
  .OurTeamSection-line {
    width: 100%;
    height: 1px;
    background: #bcbcbc;
  }
  .OurTeamSection-container {
    margin: 0 100px;
    padding: 30px 75px;
    background: #181818;
  }
  .CollaboratorItem-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .Slider-container {
    margin: 0 50px;
  }
}
@media screen and (max-width: 860px) {
  .OurTeamSection-container {
    display: none;
    margin: 0 25px;
    padding: 20px 25px;
  }
  .OurTeamSection-container-mobile {
    display: block;
  }
  .Slider-container-mobile {
    display: flex;
  }
}
@media screen and (max-width: 530px) {
  .LogoLetterBackground__OurTeam {
    display: none;
  }
  .OurTeamSection-container {
    margin: 0;
    padding: 20px 25px;
  }
}
