.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-right: 109px;
  padding-left: 170px;
  padding-top: 15px;

  position: fixed;
  width: 100%;
  z-index: 5;
  &_black {
    background: rgba(0, 0, 0, 0.65);
    transition: 1s;
  }
}

.lang-select {
  width: 70px;
  height: 41px;
  background: transparent;
  position: relative;
  cursor: pointer;

  color: #DADADA;
  font-family: Montserrat, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.5s;
    svg {
      margin-top: 3px;
      margin-left: 3px;
      transition: all 0.5s;
  }
  &--active {
    svg {
      transform: rotate(90deg);
    }
  }
  &--global-svg {
    margin-right: 5px;
  }
  &--arrow-svg {
    margin-left: 5px;
  }
}
.multiLang-svg {
  stroke: white;
  transition: all 0.5s;
}
.lang-item {
  list-style: none;
  &--button {
    background: none;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 0 20px;

    color: #DADADA;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.5s;
    user-select: none;
  }
  &--button:hover {
    color: #E51E26;
    background: rgba(36, 36, 36, 0.07);

  }
}

.select-menu {
  position: absolute;
  top: calc(100% + 5px);
  width: 75px;
  border-radius: 3px;
  border: 0.5px solid #FFF;
  box-shadow: 0 0 1px 0;
  background: rgba(57, 57, 57, 0.50);
  backdrop-filter: blur(3.5px);
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 0.3s;
  &--active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

.navigation {
  display: flex;

  align-items: center;
  gap: 30px;
  &-btn {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}
.navigation-socNetwork-links {
  display: none;
  align-items: center;
  gap: 40px;
}
.HeaderForm_button {
  display: none;
  width: 209px;
  height: 54px;
  background: rgba(231, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: all 0.5s;

  color: #FFFFFF;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 1.575px;
}

.header-navigation--link {
  color: #f2fafd;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 165.1%;
  letter-spacing: 1.575px;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
    span {
      margin-right: 10px;
    }
}
.header-navigation--link:hover {
  color: red;
}

.navigation span {
  color: #a1a1a1;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 1.575px;
}
.header-links-number {
  margin-right: 5px;
}

@media screen and (max-width: 1230px) {
  .header {
    padding-right: 70px;
    padding-left: 70px;
  }
}

@media screen and (max-width: 1140px) {
  .navigation-socNetwork-links {
    display: flex;
  }
  .header {
    padding-top: 10px;
    padding-right: 50px;
    padding-left: 40px;
  }
  .HeaderForm_button {
    display: flex;
  }
  .header-navigation--link {
    color: black;
  }
  .navigation {
    display: flex;
    gap: 55px;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    transition: 0.3s;
    transform: translateX(-250vh);
    background: #d9d9d9;

    &-responsive {
      transform: none;
      span {
        color: red;
        font-weight: 500;
        font-size: 17px;
      }
    }

    &-btn {
      visibility: visible;
      opacity: 1;
      display: block;
      background: none;
      border: none;
      outline: none;
    }
    &-close-btn {
      position: absolute;
      top: 25px;
      right: 50px;
    }
  }
}
@media screen and (max-width: 500px) {
  .header {
    padding-top: 5px;
  }
  .header-logo {
    width: 75%;
  }
}

@media screen  and (max-height: 600px) and (max-width: 1024px) {
  .navigation {
    gap: 10px;
  }
  .header-navigation--link {
    font-size: 12px;
    span {
      margin-right: 10px;
    }
  }
}
