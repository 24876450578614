@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;900&family=Murecho&family=Public+Sans:wght@500&display=swap');

body {
  background: #0b0b0b;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  font-family: Montserrat, sans-serif;
}
a {
  text-decoration: none;
}


html * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
