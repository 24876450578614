.ProjectPage-section {
  position: relative;
  width: 100%;
  padding: 150px 100px;
  overflow: hidden;
}
.ProjectPage-container {
  display: flex;
  gap: 150px;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  max-width: 1780px;
}
.ProjectPage-topPart {
  margin-top: 50px;
}

.ProjectDescription-button {
  border: none;
  background: none;
  border-bottom: #A1A1A1 1px solid;

  color: #F2FAFD;

  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 1.575px;

  max-width: 50px;
  cursor: pointer;
}

.ProjectPage-BackButton {
  max-width: 250px;
  color: #C4252B;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 165.1%; /* 24.765px */
  letter-spacing: 2.5px;
  text-transform: lowercase;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 25px;

  transition: all 0.5s;
}
.ProjectPage-BackButton:hover {
  letter-spacing: 1.5px;
  margin-left: 5px;
  border-right: white 1.5px solid;
}
.ProjectPage-topPart {
    h2 {
      white-space: nowrap;
    }
}
.ProjectPageImage {
  width: 75%;
}
.ProjectPageImage-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .ProjectPage-topPart {
    h2 {
      white-space: normal;
    }
  }
}

@media screen and (max-width: 920px) {
  .ProjectPage-topPart {
    margin-left: 50px;
  }
  .ProjectPage-section {
    padding: 50px 25px;
  }
  .ProjectPage-container {
    gap: 75px;
  }
}
@media screen and (max-width: 600px) {
  .ProjectPageImage {
    width: 100%;
  }
}
@media screen and (max-width: 460px) {
  .ProjectPage-section {
    padding: 50px 0;
  }
}