.PortfolioPage {
  position: relative;
  width: 100%;
  padding: 150px 100px;
  overflow: hidden;
}
.PortfolioPage-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  max-width: 1780px;
}
.PortfolioPage-desktop {
  width: 100%;
  padding: 50px 50px 75px 50px;
  background: #181818;
}
.PortfolioPage-Desktop-line {
  width: 80%;
  height: 1px;
  background: #bcbcbc;
  margin-top: 62px;
}

.PortfolioPage-topPart {
  margin-left: 40px;
  margin-bottom: 70px;
}
.PortfolioPage-desktop--slider-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.PortfolioPageImg {
  position: absolute;
  top: -30px;
  right: -50px;
  z-index: -1;
}
.Project-card--link {
  transition: all 0.5s;
}
.Project-card--link::after {
  content: '';
  display: block;
  width: 0;
  height: 5px;
  background: red;
  transition: width 1s;
}
.Project-card--link:hover::after {
  width: 75%;
}
.ArrowSliderProject {
  background: #181818;
  border-radius: 50%;
  border: none;
}
.PortfolioPage-desktop-mobile {
  display: none;
}
.PortfolioPage-slider-mobile-container {
  width: 100%;
}

.SwiperPortfolioPage {
  padding: 0 0 50px 0;
}

.PortfolioPage-slider-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}



@media screen and (max-width: 860px) {

  .PortfolioPage-Desktop-line {
    display: none;
  }
  .PortfolioPage-desktop {
    display: none;
  }
  .PortfolioPage-desktop-mobile {
    display: flex;
  }

  .PortfolioPage {
    padding: 150px 25px;
  }
  .PortfolioPage-desktop {
    padding: 40px 25px;
  }
}
@media screen and (max-width: 680px) {
  .ArrowSliderProject {
    opacity: .3;
  }
}
@media screen and (max-width: 530px) {
  .PortfolioPage {
    padding: 150px 0;
  }
  .PortfolioPage-desktop {
    padding: 0 0 40px 0;
  }
  .PortfolioPageImg {
    width: 150%;
  }
}

//@media screen and (max-width: 370px) {
//  .SwiperPortfolioPage {
//    height: 450px;
//  }
//}